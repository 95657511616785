// WRITE ARTICLE
import { BookOpenIcon } from "@heroicons/vue/20/solid";

export const addACitation = {
  order: 1,
  name: "Add a citation",
  keywords: "web link file earn",
  subcategory: "Build credibility",
  icon: BookOpenIcon,
  iconColor: "text-blue-500",
  markdown: `# Add a citation

  Citations provide evidence to support the accuracy and credibility of {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} in Tower Hunt's public data layer. Citations are actually datapoints, too, which means that adding citations helps you earn money and build reputation. Read on to learn how to add citations across Tower Hunt.

  ## From a datapoint

  ## From the pending contributions tab`,
};
